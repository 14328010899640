























































































import { Component, Prop, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
@Component({
  components: {
    MaterialSelect,
  },
})
export default class AddPrice extends Vue {
  @Prop() value: any
  mounted() {}
}
