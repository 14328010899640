var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-price"},[_c('div',[_c('el-form-item',{attrs:{"label":"商品价格","required":"","prop":"sell_price","rules":[
        {
          required: true,
          message: '请输入商品金额',
          trigger: ['blur', 'change'],
        } ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.sell_price),callback:function ($$v) {_vm.$set(_vm.value, "sell_price", $$v)},expression:"value.sell_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"划线价","required":"","prop":"lineation_price","rules":[
        {
          required: true,
          message: '请输入划线价',
          trigger: ['blur', 'change'],
        } ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.lineation_price),callback:function ($$v) {_vm.$set(_vm.value, "lineation_price", $$v)},expression:"value.lineation_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"成本价"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.cost_price),callback:function ($$v) {_vm.$set(_vm.value, "cost_price", $$v)},expression:"value.cost_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"库存","required":"","prop":"stock","rules":[
        {
          required: true,
          message: '请输入库存',
          trigger: ['blur', 'change'],
        } ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.stock),callback:function ($$v) {_vm.$set(_vm.value, "stock", $$v)},expression:"value.stock"}})],1),_c('el-form-item',{attrs:{"label":"体积"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.volume),callback:function ($$v) {_vm.$set(_vm.value, "volume", $$v)},expression:"value.volume"}})],1),_c('el-form-item',{attrs:{"label":"重量"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.weight),callback:function ($$v) {_vm.$set(_vm.value, "weight", $$v)},expression:"value.weight"}})],1),_c('el-form-item',{attrs:{"label":"条码"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.bar_code),callback:function ($$v) {_vm.$set(_vm.value, "bar_code", $$v)},expression:"value.bar_code"}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }