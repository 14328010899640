



































































import {Component, Vue, Watch} from 'vue-property-decorator'
// 基础设置
import AddBasic from '@/components/goods/add-basic.vue'
// 价格库存
import AddPrice from '@/components/goods/add-price.vue'
// 商品详情
import AddDetails from '@/components/goods/add-details.vue'
// 销售设置
import AddSales from '@/components/goods/add-sales.vue'
import {
  apiGetModelEncodeNumber,
  apiGoodsAdd,
  apiGoodsDetail,
  apiGoodsEdit,
  apiGoodsOtherList
} from '@/api/goods'

@Component({
  components: {
    AddBasic,
    AddPrice,
    AddDetails,
    AddSales
  }
})
export default class GoodsRelease extends Vue {
  $refs!: { form: any }
  // 商品id
  id!: any
  loading = false
  activeName = 'basic'
  otherList = {
    // 分类列表
    category_list: [],
    // 单位列表
    unit_list: [],
    // 品牌列表
    brand_list: [],
    // 供应商列表
    supplier_list: [],
    // 产品尺寸列表
    device_size_list: []
  }

  // 必传字段与对应的选项卡名字
  requireFields = [
    {
      fields: ['code', 'name', 'category_id', 'goods_image', 'goods_light_uri'],
      activeName: 'basic'
    }
  ]

  // 表单
  form: any = {
    // 商品名称
    name: '',
    // 商品编码
    code: '',
    // 商品分类
    category_id: [],
    // 3D光场视频上传
    video_source: '',
    // 视频
    video_cover: '',
    // 视频封面
    video: '',
    // 品牌
    brand_id: '',
    // 单位
    unit_id: '',
    // 供应商
    supplier_id: '',
    // 海报
    poster: '',
    // 商品2D图
    goods_image: [],
    // 商品3D 图
    goods_light_uri: '',
    //
    spec_type: 1,
    // 价格
    sell_price: '',
    // 划线价
    lineation_price: '',
    // 成本
    cost_price: '',
    // 库存
    stock: '',
    // 体积
    volume: '',
    // 重量
    weight: '',
    // 条码
    bar_code: '',
    // 库存预警
    stock_warning: '',
    // 虚拟销量
    virtual_sales_num: '',
    // 商品状态
    status: 0,
    // 商品详情
    content: '',

    //  新增
    row_num: '',
    col_num: '',
    // 产品尺寸
    device_size: '',
    is_video: 0,
    image: '',
    // 素材地址
    extend:''
  }

// 规则验证
  rules = {
    name: [
      {
        required: true,
        message: '请输入商品名称',
        trigger: ['blur', 'change']
      }
    ],
    code: [
      {
        required: true,
        message: '请输入商品编码',
        trigger: ['blur', 'change']
      }
    ],
    category_id: [
      {
        // type: 'array',
        required: true,
        message: '请选择分类',
        trigger: ['blur', 'change']
      }
    ],
    goods_image: [
      {
        type: 'array',
        required: true,
        message: '请添加商品2D图',
        trigger: ['blur', 'change']
      }
    ],
    /*goods_light_uri: [
      {
        // type: 'array',
        required: true,
        message: '请添加商品3D 图',
        trigger: ['blur', 'change']
      }
    ],*/
    video: [
      {
        required: true,
        message: '请添加视频',
        trigger: ['blur', 'change']
      }
    ],
    //  新增
    row_num: [
      {
        required: true,
        message: '请输入行数',
        trigger: ['blur', 'change']
      }
    ],
    col_num: [
      {
        required: true,
        message: '请输入列数',
        trigger: ['blur', 'change']
      }
    ],
    // 设备尺寸验证
    device_size: [
      {
        required: true,
        message: '请选择适用于模型的尺寸',
        trigger: ['blur', 'change']
      }
    ]
  }

  @Watch('form', {deep: true})
  formChange(val
                 :
                 any
  ) {
    // 解决新增商品模板问题
    // eslint-disable-next-line eqeqeq

    // eslint-disable-next-line eqeqeq
    if (this.id == undefined) {
      val.content = '<p>规格尺寸:</p>' +
          '<p>适用型号:</p>' +
          '<p>技术参数:</p>' +
          '<p>应用方向:</p>' +
          '<p>版权归属:</p>'

    }
  }

// methods
  getGoodsDetail() {
    apiGoodsDetail(this.id).then((res: any) => {
      console.log(res)
      this.form = res
    })
  }

  onNextStep(isNext = true) {
    switch (this.activeName) {
      case 'basic':
        this.activeName = 'price'
        break
      case 'price':
        this.activeName = isNext ? 'details' : 'basic'
        break
      case 'details':
        this.activeName = isNext ? 'sales' : 'price'
        break
      case 'sales':
        this.activeName = 'details'
        break
    }
  }

  handleSave() {
    this.$refs.form.validate((valid: boolean, object: any) => {
      if (valid) {
        const loading = this.$loading({
          lock: true,
          text: '保存中...',
          spinner: 'el-icon-loading'
        })
        const api = this.id ? apiGoodsEdit(this.form) : apiGoodsAdd(this.form)
        api
            .then(() => {
              this.$router.go(-1)
            })
            .finally(() => {
              loading.close()
            })
      } else {
        const fieldsitem = this.requireFields.find((item) => {
          for (const value of item.fields) {
            if (object[value]) {
              this.$message.error(object[value][0].message)
              return true
            }
          }
        })
        fieldsitem && (this.activeName = fieldsitem.activeName)
        return false
      }
    })
  }

  getGoodsOtherList() {
    apiGoodsOtherList({
      type: 'all'
    }).then((res: any) => {
      console.log(res)
      this.otherList = res
    })
  }

  created() {
    this.id = this.$route.query.id
    this.getGoodsOtherList()
    this.getModelEncodeNumber()

    if (this.id) {
      this.getGoodsDetail()
      // return
    } else {

    }
  }

// 获取模型编号
  getModelEncodeNumber() {
    // eslint-disable-next-line no-undef
    apiGetModelEncodeNumber({}).then((res: any) => {
      this.form.code = res.data
    })
  }
}
