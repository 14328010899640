



































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'

import config from '@/config'
import {apiGetModelEncodeNumber} from '@/api/goods'
import {longNumberDate} from "@/utils/moment";

@Component({
  components: {
    MaterialSelect
  }
})
export default class AddBasic extends Vue {
  @Prop() value: any
  @Prop({default: () => ({})}) lists: any
  // 进度条
  progressFlag = {
    obj: false,
    threeD: false,
    twoD: false
  }
  // 进度条状态
  progressPercent = 0
  // isImageOrVideo = 1
  addVideo = false
  form = {}
  fileList = []
  fileList3D = []
  uploadImg: []
  fileArr: any

  upload_3d_or_not = false
  upload_3d_name = ''


  @Watch('value.video', {immediate: true})
  videoChange(val: string) {
    if (val) {
      this.addVideo = true
    }
  }

  get categoryList() {
    return this.lists.category_list || []
  }

  get unitList() {
    return this.lists.unit_list || []
  }

  get brandList() {
    return this.lists.brand_list || []
  }

  get supplierList() {
    return this.lists.supplier_list || []
  }

  // 设备类型
  get deviceSizeList() {
    return this.lists.device_size_list || []
  }


  // S 文件上传

  $refs!: {
    upload2D: any
    upload3D: any
  }


  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleChange(file: any, fileList: any) {
    this.upload_3d_or_not = true
    this.fileList3D = fileList
    this.upload_3d_name = fileList[0].name
    // const type = fileList[0].raw.type.substring(0, fileList[0].raw.type.indexOf('/'))
    // console.log('type => ', type)
    // if (type == 'image') {
    //   this.file_raw_type = true
    // } else if (type == 'video') {
    //   this.file_raw_type = false
    // }
  }

  UploadFile3D(data: any) {
    this.progressFlag.threeD = true
    console.log('开始分片上传')
    const file = data.file
    // eslint-disable-next-line @typescript-eslint/no-this-alias,no-unreachable
    const _this = this
    _this.uploadImg = []
    // 开始文件上传到oss
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const OSS = require('ali-oss')

    const client = new OSS({
      accessKeyId: 'LTAI5t6CTbjsxAa1kz4x3RCp',
      accessKeySecret: 'eNlsZparfM5VkW3OSQJ9MkbH0UJH8D',
      // stsToken: '',
      region: 'oss-cn-qingdao',
      bucket: 'imagetru3d'
    })
    console.log('初始化OSS')
    const inputFiles = {files: data.file}

    if (inputFiles.files) {
      const file = inputFiles.files
      const date = new Date()
      const path = 'uploads/light/' + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + date.getMilliseconds() + '.' + file.name.split('.').pop()
      const size = file.size
      if (Math.round(size / (1024 * 1024) * 100) / 100 <= 1024) {
        client.multipartUpload(path, file, {
          progress: function (p: any, checkpoint: any) {
            _this.progressPercent = Math.floor(p * 100)
          }
        }).then((results: { res: { requestUrls: any[] } }) => {
          // 上传成功如果宣选中的是图片则改图片链接，是视频则改视频链接
          if (this.value.is_video == 0) {
            this.value.video_source = ''
            const old_url = results.res.requestUrls[0]
            this.value.goods_light_uri = old_url.split('?')[0]
          }
          if (this.value.is_video == 1) {
            this.value.goods_light_uri = ''
            const old_url = results.res.requestUrls[0]
            this.value.video_source = old_url.split('?')[0]
          }
          this.progressFlag.threeD = false
        }).catch((err: any) => {
          console.log(err)
          this.$Message.error('上传失败，请重试!')
        })
      } else {
        this.$Message.error('上传不能超过2M，请重试!')
      }
    }
  }

  // 整合的Oss上传
  async OSSUpload(file: any, fileNameExtension: string) {
    if (fileNameExtension == 'exe') {
      this.progressFlag.obj = true;
    }
    const OSS = require('ali-oss')
    const client = new OSS({
      accessKeyId: 'LTAI5t6CTbjsxAa1kz4x3RCp',
      accessKeySecret: 'eNlsZparfM5VkW3OSQJ9MkbH0UJH8D',
      // stsToken: '',
      region: 'oss-cn-qingdao',
      bucket: 'imagetru3d'
    })
    let that = this;
    const path = 'uploads/light/' + longNumberDate(new Date()) + '.' + fileNameExtension;
    const size = file.size
    if (Math.round(size / (1024 * 1024) * 100) / 100 <= 1024) {
      client.multipartUpload(path, file, {
        progress: function (p: any, checkpoint: any) {
          that.progressPercent = Math.floor(p * 100)
        }
      }).then((results: any) => {
        const url = results.res.requestUrls[0].split('?')[0];
        if (fileNameExtension == 'obj' || fileNameExtension == 'fbx') {
          this.value.extend = url
        } else if (this.value.is_video == 1) {
          this.value.video_source = url
        } else {
          this.value.goods_light_uri = url
        }
        this.progressFlag = {
          obj: false,
          threeD: false,
          twoD: false
        }
      })
    }
  }

  // 上传素材
  UploadFile(data: any) {
    const fileNameExtension = data.file.name.split('.').pop();
    this.progressFlag.obj = true;
    this.OSSUpload(data.file, fileNameExtension);
  }

  /**
   * 上传文件钩子
   * @param event
   * @param file
   * @param FileList
   */
  uploadProgress(event: any, file: any, FileList: any) {
    // eslint-disable-next-line no-undef
    console.log('开始处理进度条')
    console.log(event)
  }

  // // 3d 素材上传成功时的回调
  // handleSuccessFor3D(response: any, file: any, fileList: any[]) {
  //
  //   // this.data3DMaterail = response.data
  //   // this.value.goods_light_uri = response.data.uri
  //   console.log('3D', this.value)
  // }

  // 版本号，用于调用接口
  // headers 上传的请求头部
  version = config.version

  // 上传所调用的接口
  action = `${config.baseURL}/adminapi/upload/lightFile`

  // 2d 素材上传失败时的回调
  // eslint-disable-next-line handle-callback-err
  handleErrorFor2D(err: any, file: any) {
    this.$message.error(`${file.name}文件上传失败`)
    this.$refs.upload2D.abort()
  }

  // 3d 素材上传失败时的回调
  handleErrorFor3D(err: any, file: any) {
    this.$message.error(`${file.name}文件上传失败`)
    this.$refs.upload3D.abort()
  }

  // 文件超出个数限制时的回调
  handleExceed() {
    this.$message.warning('当前限制上传 1 个文件，现已超出上传上限，请重新上传')
  }

  // 删除文件前的回调
  beforeRemove(file: any) {
    return this.$confirm(`确定移除 ${file.name}？`)
  }

  /**
   * 1.先判断当前所选是图片还是视频
   * 2.是图片清空光场2D的uri
   * 3.是视频的话清空3D的video_source
   */

  handleRemoveFor2D() {
    // this.$refs.upload2D.clearFiles()
    this.value.goods_image = []
  }

  handleRemoveFor3D() {
    // this.$refs.upload3D.clearFiles()
    this.value.goods_light_uri = ''
  }

  // E
}
